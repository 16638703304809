import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Subscription,
  filter,
  fromEvent,
  merge,
  switchMap,
  timer,
  takeUntil,
} from 'rxjs';
import { Amplify, Auth, Storage } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { UserRoles } from 'src/app/shared/enums/roles-enum';
import { FirebaseApp } from '@angular/fire/app';
import { getMessaging, getToken } from '@angular/fire/messaging';
import { RegisteredDevicesService } from 'src/app/shared/services/registered-devices.service';
import { MatDialog } from '@angular/material/dialog';
import { LogoutCountdownComponent } from '../components/logout-countdown/logout-countdown.component';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

export interface IUser {
  email: string;
  password: string;
  showPassword: boolean;
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService implements OnDestroy {
  private authenticationSubject: BehaviorSubject<any>;
  minutesToMilliseconds = (minutes: number) => minutes * 60000;
  AUTO_LOGOUT_INTERVAL_WAIT = this.minutesToMilliseconds(0.05);

  private pauseInactiveListener = new BehaviorSubject<boolean>(false);
  private resetTimerSubscription = new Subscription();

  constructor(
    private afApp: FirebaseApp,
    private registeredDevices: RegisteredDevicesService,
    private dialog: MatDialog,
    private router: Router
  ) {
    //this.initializeInactiveListener();
    Amplify.configure({
      Auth: environment.cognito,
      Storage: {
        AWSS3: environment.s3,
      },
    });
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  openLogoutModal() {
    // Pausar la escucha de eventos cuando se abre el modal para validar
    // si el usuario desea continuar con la sesión abierta
    this.pauseInactiveListener.next(true);
    const dialogRef = this.dialog.open(LogoutCountdownComponent, {
      width: '350px',
      data: {},

      disableClose: true,
    });
    // Cuando se cierre la sesión, el modal devolvera un booleano
    // que sera true o false dependiendo de si se desea o no
    // continuar con la sesión abierta
    dialogRef.afterClosed().subscribe((keepSession) => {
      if (keepSession) {
        // Refrescar la sesión y luego habilita la escucha de eventos
        this.getSession().then(() => {
          this.pauseInactiveListener.next(false);
        });
      } else {
        // Ejecuta el logout
        this.signOut().then(() => {
          this.router.navigate([['auth/login']]);
        });
      }
    });
  }

  initializeInactiveListener() {
    //Escuchar los eventos del mouse que tomamos en cuenta
    // para determinar que el usuario esta interactuando con la página
    const mouseMove$ = fromEvent(document, 'mousemove');
    const click$ = fromEvent(document, 'click');
    // Pausar la escucha de los eventos cuando el observable de pausa esta
    // habilitado, esto se ejecuta al abrir el modal de log out.
    this.resetTimerSubscription = merge(mouseMove$, click$)
      .pipe(
        filter(() => !this.pauseInactiveListener.value),
        // Usando el switchmap se cancela el observable anterior si
        // todavía no se ejecuta, en este caso luego de 10min y
        // se completa el observable si una nueva emisión es ejecutada
        switchMap(() => {
          return timer(this.AUTO_LOGOUT_INTERVAL_WAIT).pipe(
            takeUntil(merge(mouseMove$, click$))
          );
        })
      )
      .subscribe(() => {
        // Al obtener una emisión que cumple las validaciones antes
        // añadidas, se verifica la validez de la sesión y si no es
        // válida, se valida el observable de pausa, si esta desactivado
        // abre la ventana de logout de modal
        this.getSession().then((credentials) => {
          if (credentials) {
            if (!this.pauseInactiveListener.value) {
              this.openLogoutModal();
            }
          }
        });
      });
  }


  cleanComponentSubscriptions() {
    if (this.resetTimerSubscription) {
      this.resetTimerSubscription.unsubscribe();
    }
  }
  // Limpiar la suscripción del timer cuando el componente se destruye
  ngOnDestroy() {
    //this.cleanComponentSubscriptions();
  }

  public signUp(email: string, password: string, isCompany: number): Promise<any> {
    return Auth.signUp({
      username: email,
      password: password,
      attributes: {
        'custom:user-group': UserRoles.aguacate_client,
        'custom:is-company': `${isCompany}`
      },
    });
  }
  public resendConfirmationCode(email:string):Promise<any>{
    return Auth.resendSignUp( email
    );
  }
  public resetPassword(email: string) {
    return Auth.forgotPassword(email);
  }

  checkTokenCloudMessage(email: string) {
    const messaging = getMessaging(this.afApp);
    getToken(messaging, { vapidKey: environment.firebase.applicationServerKey })
      .then((currentToken) => {
        if (currentToken) {
          this.registeredDevices.deleteDevice(email, currentToken);
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  public completeResetPassword(email: string, code: string, password: string) {
    return Auth.forgotPasswordSubmit(email, code, password);
  }
  public confirmSignUp(email: string, code: string): Promise<any> {
    return Auth.confirmSignUp(email, code);
  }

  public async signIn(email: string, password: string): Promise<any> {
    try {
      const response = await Auth.signIn(email, password);
      console.log(response);
      if (response.challengeName === 'SOFTWARE_TOKEN_MFA') {
        const result = await Swal.fire({
          title: 'Ingresa Código de un Solo Uso',
          input: 'text',
          inputLabel: 'Ingrese el código generado',
          inputPlaceholder: 'Código OTP',
          showCancelButton: true,
          confirmButtonText: 'Verificar',
          confirmButtonColor: '#1e5a3f',
          cancelButtonText: 'Cancelar',
          preConfirm: (otpCode) => {
            if (!otpCode) {
              Swal.showValidationMessage('El código OTP es requerido');
            }
            return otpCode;
          }
        });

        if (result.isConfirmed) {
          try {
            await this.confirmSignIn(response, result.value);
            this.authenticationSubject.next(true);
          } catch (err) {
            Swal.fire('Error', 'Código OTP incorrecto', 'error');
          }
        }
      } else {
        this.authenticationSubject.next(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  public async signOut(): Promise<any> {
    const user = await this.getUser();
    const email = user.attributes.email;
    return Auth.signOut().then(() => {
      if (email) this.checkTokenCloudMessage(email);
      this.cleanComponentSubscriptions();
      this.authenticationSubject.next(false);
    });
  }

  public startMFASetup(): Promise<any> {
    return Auth.currentAuthenticatedUser().then((user) => {
      return Auth.setupTOTP(user);
    });
  }

public async generateQRCode(): Promise<string> {
  const user = await Auth.currentAuthenticatedUser();
  const code = await Auth.setupTOTP(user);
  const issuer = encodeURIComponent('Aguacate Wallet');
  const label = encodeURIComponent(user.attributes.email);
  const uri = `otpauth://totp/${issuer}:${label}?secret=${code}&issuer=${issuer}`;
  return uri;
}
  public verifySoftwareToken(code: string): Promise<any> {
    return Auth.currentAuthenticatedUser().then((user) => {
      return Auth.verifyTotpToken(user, code);
    });
  }

  public confirmSignIn(user: any, code: string): Promise<any> {
    return Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA');
  }

  public getMFAStatus(): Promise<any> {
    return Auth.currentAuthenticatedUser().then((user) => {
      return Auth.getPreferredMFA(user);
    });
  }

  public enableMFA(): Promise<any> {
    return Auth.currentAuthenticatedUser().then((user) => {
      return Auth.setPreferredMFA(user, 'TOTP');
    });
  }

  public isAuthenticated(): Promise<boolean> {
    if (this.authenticationSubject.value) {
      return Promise.resolve(true);
    } else {
      return this.getUser()
        .then((user: any) => {
          if (user) {
            return true;
          } else {
            return false;
          }
        }, (error)=>{
          return false;
        }).catch(err => {
          return false;
        });

    }
  }

  public getUser(): Promise<any> {
    return Auth.currentUserInfo().catch((err)=>{
      console.log('No hay usuario autenticado')
    });
  }

  public getAuthUser(): Promise<any> {
    return Auth.currentAuthenticatedUser().catch(()=>{
      console.log('No hay usuario autenticado')
    })
  }

  public getSession(): Promise<any> {
    return Auth.currentSession().catch(()=>{
      console.log('No hay sesión activa')
    });
  }

  public updateUser(user: IUser): Promise<any> {
    return Auth.currentUserPoolUser().then((cognitoUser: any) => {
      return Auth.updateUserAttributes(cognitoUser, user);
    });
  }
}
