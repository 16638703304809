<div class="p-6 space-y-4 md:space-y-6 sm:p-8">
  <div class="container flex flex-row items-center">
    <h1 class="text-xl font-bold">
      Actualizar Tasas
    </h1>
  </div>
  <form *ngIf="formCuenta" class="space-y-4 md:space-y-6" [formGroup]="formCuenta">
    <div class="grid grid-cols-2 gap-4">
      <mat-form-field>
        <mat-label>Tasa CLP</mat-label>
        <input type="number" matInput formControlName="tasaCLP" step="0.1"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Porcentaje comisión</mat-label>
        <input type="number" matInput formControlName="porcentajeComision" step="0.1"/>
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <mat-form-field>
        <mat-label>Tasa Banesco</mat-label>
        <input type="number" matInput formControlName="tasaBanesco" step="0.01"/>
        <span class="secondary-label">Tasa actual: {{ (this.calculoTasaMax(this.formCuenta.get("tasaCLP")?.value, this.formCuenta.get("tasaBanesco")?.value, 1)).toFixed(5)}}</span>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Tasa Provincial</mat-label>
        <input type="number" matInput formControlName="tasaProvincial" step="0.01"/>
        <span class="secondary-label">Tasa actual: {{ (this.calculoTasaMax(this.formCuenta.get("tasaCLP")?.value, this.formCuenta.get("tasaProvincial")?.value, 1)).toFixed(5)}}</span>
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <mat-form-field>
        <mat-label>Tasa Mercantil</mat-label>
        <input type="number" matInput formControlName="tasaMercantil" step="0.01"/>
        <span class="secondary-label">Tasa actual: {{ (this.calculoTasaMax(this.formCuenta.get("tasaCLP")?.value, this.formCuenta.get("tasaMercantil")?.value, 1)).toFixed(5)}}</span>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Tasa Venezuela</mat-label>
        <input type="number" matInput formControlName="tasaVenezuela" step="0.01"/>
        <span class="secondary-label">Tasa actual: {{ (this.calculoTasaMax(this.formCuenta.get("tasaCLP")?.value, this.formCuenta.get("tasaVenezuela")?.value, 1)).toFixed(5)}}</span>
      </mat-form-field>
    </div>
    <div class="container flex flex-col items-center">
      <button mat-raised-button color="primary" (click)="actualizarTasa()"
      class="w-1/2 text-white focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" [disabled]="formCuenta.invalid || formCuenta.hasError('outOfRange')">
        Guardar
      </button>
      <mat-error *ngIf="formCuenta.get('tasaProvincial')?.hasError('outOfRange')">
        Tasa Provincial no puede ser ±0.01 de {{ (this.calculoTasaMax(this.formCuenta.get("tasaCLP")?.value, this.data.tasaProvincial, 1)).toFixed(5) }}.
      </mat-error>
      <mat-error *ngIf="formCuenta.get('tasaBanesco')?.hasError('outOfRange')">
        Tasa Banesco no puede ser ±0.01 de {{ (this.calculoTasaMax(this.formCuenta.get("tasaCLP")?.value, this.data.tasaBanesco, 1)).toFixed(5) }}.
      </mat-error>
      <mat-error *ngIf="formCuenta.get('tasaMercantil')?.hasError('outOfRange')">
        Tasa Mercantil no puede ser ±0.01 de {{ (this.calculoTasaMax(this.formCuenta.get("tasaCLP")?.value, this.data.tasaMercantil, 1)).toFixed(5) }}.
      </mat-error>
      <mat-error *ngIf="formCuenta.get('tasaVenezuela')?.hasError('outOfRange')">
        Tasa Venezuela no puede ser ±0.01 de {{ (this.calculoTasaMax(this.formCuenta.get("tasaCLP")?.value, this.data.tasaVenezuela, 1)).toFixed(5) }}.
      </mat-error>
      <mat-error *ngIf="formCuenta.invalid || formCuenta.hasError('outOfRange')">
        Tasas no se pueden actualizar.
      </mat-error>
    </div>
  </form>
</div>