import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CuentaBeneficiario } from 'src/app/dashboard/interfaces/cuenta-beneficiario.interface';
import { HoursOperationService } from 'src/app/dashboard/services/hours-operation.service';
import { SimpleExchangeService } from 'src/app/dashboard/services/simple-exchange.service';
import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
import { ErrorTypes } from 'src/app/shared/enums/error-types.enum';
import { ActionsService } from 'src/app/shared/services/actions.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-single-money-order',
  templateUrl: './single-money-order.component.html',
  styleUrls: ['./single-money-order.component.css']
})
export class SingleMoneyOrderComponent implements OnInit, OnDestroy {
  estadoOperaciones = true;
  estadoStopOperaciones = true;
  isVertical= false;
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (window.innerWidth <= 768) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
  }

  @ViewChild('stepper') stepper: MatStepper | undefined;
  cuentaBeneficiario: CuentaBeneficiario | undefined;
  envioData: any | undefined;
  validPayer = false;
  medioPago: string | undefined = undefined;
  subscription: Subscription = new Subscription();
  constructor(
    private actionService: ActionsService, private hourOperations: HoursOperationService,
    private spinnerService: SpinnerService, private transaccionService: TransaccionService, private router: Router ) { }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();


  }
  async validarEstado(){
    this.estadoOperaciones = await this.hourOperations.getEstadoOperacion('FIAT');
    this.estadoStopOperaciones = await this.hourOperations.getEstadoOperacion('FIAT_STOP');
    const date = new Date().getDay();
    const message = [6,7].includes(date) ? 'Hemos cerrado operaciones, muchas gracias por preferirnos, Nos vemos el Lunes a las 9:00 am' :
    'Hemos cerrado operaciones, muchas gracias por preferirnos, Nos vemos Mañana a las 9:00 am'
    console.log(date);
    if(!this.estadoOperaciones){
      Swal.fire(
        { confirmButtonColor: '#1e5a3f',
        title: 'Operación cerrada',
        text:message,
      }
      );
      this.router.navigate(['/wallet']);
    }
    if(!this.estadoStopOperaciones){
      Swal.fire(
        { confirmButtonColor: '#1e5a3f',
        title: 'Operación Pausada',
        text:'Volvemos en 10 min',
      }
      );
      this.router.navigate(['/wallet']);
    }
  }
  ngOnInit() {
    this.validarEstado();
    this.onWindowResize();
    this.subscription.add(
      this.actionService.subjectTasaFiat.subscribe(res=>{
        Swal.fire('Tasas actualizadas', 'Se han actualizado las tasas, será regresado al primer paso', 'warning').then(()=>{
          if(this.stepper){
            this.stepper.reset();

          }

        });
        console.log('Se recibió push', res);
      })
    )
  }

  continuar(stepper: MatStepper){
    stepper.next();
  }
  calculadoraBlur(data:any){
    this.envioData = data;
  }

  dataCalculadora(data: any){
    console.log('entra');
    this.envioData = data;
    this.validPayer = false;



  }

  continuarCalculadora(){
    console.log(this.envioData);
    if(this.envioData){
      setTimeout(() => {
        if(this.stepper){
          this.stepper.next();
          this.validPayer = false;

        }
      }, 300);
    }
  }

  onMedioPagoSeleccionado(medioPagoSeleccionado: string){
    this.medioPago = medioPagoSeleccionado;

  }
  onCuentaSeleccionada(cuenta: CuentaBeneficiario){
    this.cuentaBeneficiario = cuenta;


  }

  selectBeneficiarioStep(){

    if(! this.cuentaBeneficiario)return;
    if(this.cuentaBeneficiario.banco?.bancoID !== this.envioData.banco.bancoID){

      this.validPayer = false;
      Swal.fire(
        { confirmButtonColor: '#1e5a3f',
        title: 'Revisa tus datos',
        text:'El banco seleccionado en la calculadora y el del beneficiario deben ser el mismo',

      }
      );
      return;
    }else{
      this.validPayer= true;

    }
    if(this.stepper){
      setTimeout(() => {
        if(this.stepper){
          this.stepper.next();
        }
      }, 300);
      this.stepper.next();
    };

  }
  async confirmarEnvio(){
    const ref = this.spinnerService.start();
    const transaccionDTO ={
    "paisOrigen":'CL', "userId":this.cuentaBeneficiario?.usuarioID,
    "totalMonedaOrigen": +this.envioData.envia,"totalMonedaDestino":this.envioData.recibe,"monedaOrigen":'CLP',
    "monedaDestino":'VES',"paisDestino":'VE',"formaPago":this.medioPago,"diferencia":null,
    "tasa":this.envioData.tasa,"cuentaBeneficiarioId":this.cuentaBeneficiario?.cuentaBeneficiarioID,"tasaCambioId":this.envioData.tasaId,"valorCambioId":this.envioData.valueId,
    }
    try{
      const resultadoTransaccion = await this.transaccionService.saveTransaccion(transaccionDTO);
      this.spinnerService.stop(ref);
      Swal.fire('Transaccion creada', `Se ha creado la transaccion con éxito`, 'success' );
      this.router.navigate(['/wallet']);

    }catch(error: any){
      if(error.status === 400 && error.error.type === ErrorTypes.TASAS_ACTUALIZADAS){
        this.spinnerService.stop(ref);
          if(this.stepper){
            this.actionService.emitirAccionTasaFiat();
            this.stepper.reset();

          }
      }else{
        Swal.fire('Error al enviar dinero',error.error.title, 'warning').then(()=>{
          this.spinnerService.stop(ref);
          this.router.navigate(['/wallet']);
        });
      }

    }

  }
}
