<div class="m-4 overflow-x-auto items-center">
  <h1 class="m-4 text-2xl font-bold text-center"> Compliance </h1>
  <div class="flex flex-row gap-5 px-20 justify-around" style="max-width: 800px; margin: 0 auto;">
    <mat-form-field class="w-full max-w-screen-md mx-auto">
      <mat-label>Valor de búsqueda</mat-label>
      <input matInput type="text" (keyup)="doFilter($event)" placeholder="búsqueda">
    </mat-form-field>
    <mat-form-field class="w-full max-w-screen-md mx-auto">
      <mat-select (selectionChange)="doFilter($event.value)" placeholder="Búsqueda por estado">
        <mat-option *ngFor="let option of filterOptions" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="m-4 ">
    <div class="paginator-container">
      <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      showFirstLastButtons
      itemsPerPageLabel="itemsPerPageLabel"
      previousPageLabel="previousPageLabel"
      nextPageLabel="nextPageLabel"
      firstPageLabel="firstPageLabel"
      lastPageLabel="lastPageLabel"
      [pageSize]="defaultPageSize">
      </mat-paginator>
    </div>
    <div class="table-container">
      <mat-table [dataSource]="dataSource" *ngIf="dataSource">
        <ng-container
          *ngFor="let column of columns"
          [matColumnDef]="column.columnDef"
        >
          <mat-header-cell
          *matHeaderCellDef
          style="background-color: #CEE9C2;"
          [ngStyle]="column.headerStyle"
          >
            {{column.header}}
          </mat-header-cell>

          <mat-cell
          *matCellDef="let row"
          [ngStyle]="column.cellStyle"
          >
            <ng-container *ngIf="column.columnDef === 'estado_compliance' && column.iconColor; else otherColumns">
              <mat-icon [style.color]="column.iconColor(row)" [matTooltip]="column.val(row)">{{ column.cell(row) }}</mat-icon>
            </ng-container>
            <ng-template #otherColumns>
              {{ column.cell(row) }}
            </ng-template>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="acciones" stickyEnd>
          <mat-header-cell
          *matHeaderCellDef
          aria-label="row actions"
          class="text-center font-bold min-w-[100px]"
          style="background-color: #CEE9C2;"
          >
            Acciones
          </mat-header-cell>
          <mat-cell *matCellDef="let element" style="text-align: center; min-width:100px;">
            <button mat-icon-button color="primary" aria-label="editar" [routerLink]="['/wallet/admin/compliance',element.email]">
              <mat-icon>edit</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
