<div class="container_change">
  <app-usdt-change [usdt_clp_tax]="usdt_clp_tax"></app-usdt-change>
  <div class="text-center">
    <h2><b>Saldo actual: </b> ${{saldoActual | currency:'':''}} CLP</h2>
    <mat-error *ngIf="lineaCreditoBloqueada">Su linea de credito se encuentra actualmente bloqueada</mat-error>
    <div *ngIf="lineaCredito&&lineaCredito>0&&!lineaCreditoBloqueada">
    <h2><b>Saldo contable: </b> ${{saldoContable | currency:'':''}} CLP</h2>
    <h2><b>Sobregiro disponible: </b> ${{sobregiroDisponible | currency:'':''}} CLP</h2>
    </div>
    <h2 class="font-bold">Horario de operación: 9:00 - 21:00 </h2>
  </div>
  <div class="mt-10">
    <div class="grid grid-cols-1 send-input">
      <input
        type="text"
        [(ngModel)]="valorClp"
        (keyup)="transformCurrency('clp')"
        (change)="emitAmountChange()"
        placeholder="$"
        pattern="[0-9]*"
        (keypress)="soloNumeros($event)"
        maxlength="10"
        >
      <span class="button-inline flex flex-row items-center">
        <button *ngIf="valorClp!=''" class="close-btn hover:border-gray-700/50 border-transparent border-1"(click)="clearValAvailableCLP()">✕</button>
        <button class="max-btn hover:bg-aguacate-highlight" (click)="maxValAvailableCLP()">MÁX.</button>
      </span>
      <span>Tu envias CLP</span>
      <mat-error *ngIf="lineaCredito === null  && clpCurrency > saldoActual">El monto de envio excede el saldo actual</mat-error>
      <mat-error *ngIf="lineaCredito !==null && lineaCredito! >0 && clpCurrency > saldoContable!">El monto de envio excede el saldo disponible</mat-error>

    </div>
    <img src="assets/img/transaccion.png" class="mb-5">
    <div class="grid grid-cols-1">
      <input
        type="text"
        [(ngModel)]="valorUsdt"
        (keyup)="transformCurrency('usdt')"
        (change)="transformCurrency('usdt')"
        placeholder="$"
        pattern="[0-9]*"
        (keypress)="soloNumeros($event)"
        maxlength="8"
      >
      <span>Tu destinatario recibe USDT</span>
      <mat-error *ngIf="minError">El monto mínimo para enviar es de 50 USDT</mat-error>
    </div>
  </div>
</div>
<div class="text-center">
  <button [disabled]="clpCurrency === 0 || usdtCurrency === 0 || (lineaCredito === null  && clpCurrency > saldoActual)||(lineaCredito!>0&&clpCurrency>saldoContable!) || lineaCreditoBloqueada" (click)="emitChangeStep()" mat-raised-button color="primary" class="mt-10 p-5 text-lg">
    Comprar
  </button>
</div>
