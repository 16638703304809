<div class="p-4 flex flex-col md:p-14 gap-[1rem] mt-2">
  <ng-container *ngIf="userRole === userEnum.aguacate_client">
    <ng-container *ngIf="estadoValidacion === 2">
      <div>
        <h1 class="ml-4 text-lg ">Hola, <span class="font-bold">{{user.nombre}}</span></h1>
        <div class="rounded-[40px] border-4 shadow-2xl border-lime-900 max-w-xs mt-8 text-center ml-4">
            <p class="text-4xl font-bold mt-10 my-2 mx-4">${{ saldoActual | customNumberPipe }} CLP</p>
            <p class="text-lg font-bold italic mb-4 mx-4">Saldo Actual</p>
            <div *ngIf="lineaCredito&&lineaCredito>0&&!lineaCreditoBloqueada">
            <p class="text-2xl font-bold my-2 mx-4">${{ saldoContable | customNumberPipe }} CLP</p>
            <p class="text-md font-bold italic mb-4 mx-4">Saldo Contable</p>
            <p class="text-2xl font-bold my-2 mx-4">${{ sobregiroDisponible | customNumberPipe }} CLP</p>
            <p class="text-md font-bold italic mb-4 mx-4">Sobregiro Disponible</p>
            </div>
            <div *ngIf="lineaCredito&&lineaCredito>0&&lineaCreditoBloqueada">
              <mat-error *ngIf="lineaCreditoBloqueada">Su linea de credito se encuentra actualmente bloqueada</mat-error>
              <p class="text-2xl font-bold my-2 mx-4">${{ sobregiroDisponible | customNumberPipe }} CLP</p>
              <p class="text-md font-bold italic mb-4 mx-4">Sobregiro Bloqueado</p>
              </div>
        </div>
      </div>
      <h1 class="text-2xl font-bold mx-4 mt-4 mb-4">¿Qué deseas realizar hoy?</h1>
      <div class="w-full md:w-2/4 flex flex-row md:self-start gap-10 justify-start mb-4" >
        <div class=" flex flex-col items-center justify-betwee aguacate-icons" routerLink="/wallet/client/rechargeAccount">
          <img src="./assets/icons/charge_wallet.svg" class="w-[40px] ">
          <p class="text-sm font-bold mt-2">Recargar Saldo</p>
        </div>
        <div class="flex flex-col items-center justify-between aguacate-icons"  routerLink="/wallet/client/send-cripto">
          <img src="./assets/icons/buy_usd.svg" class="w-[40px]">
          <p class="text-sm font-bold mt-2">Comprar USDT</p>
        </div>
        <div class="flex flex-col items-center justify-between aguacate-icons" routerLink="/wallet/client/remittance">
          <img src="./assets/icons/send_fiat.svg" class="w-[40px]">
          <p class="text-sm font-bold mt-2">Enviar Remesa</p>
        </div>
        <div class="flex flex-col items-center justify-between aguacate-icons"  routerLink="/wallet/client/zelle-home">
          <img src="./assets/icons/send_usd.svg" class="w-[40px]">
          <p class="text-sm font-bold mt-2">Envío Zelle</p>
        </div>

        <div class="flex flex-col items-center justify-between aguacate-icons"   routerLink="/wallet/client/withdraw"  *ngIf="userEmail==='f751e3d84d03360fbf81118174fa14c8c112ccec56a34d153b6837cbb28c66d3'||userEmail==='06c285de186979ebd4d8fa3422e16065fa0998ac46c75c53fe2e9d39d0f802bf'">
          <img src="./assets/icons/send_usd.svg" class="w-[40px]">
          <p class="text-sm font-bold mt-2">Realizar Retiro</p>
        </div>

      </div>
      <hr>
      <app-transactions></app-transactions>
    </ng-container>

    <ng-container *ngIf="estadoValidacion===0">
      <button mat-raised-button color="primary" class="w-full md:w-1/3 py-5" routerLink="/wallet/client/user-validation">Verifica tu cuenta</button>
      <span class="px-6 py-4 bg-aguacate-green/25 rounded-lg  font-semibold">Para procesar tus envíos es necesario que nos
        facilites algunos datos personales, esto con el objetivo de hacer tus envíos mas seguros y verificar tu identidad.
      </span>
    </ng-container>
    <ng-container *ngIf="estadoValidacion===1">
      <span class="px-6 py-4 bg-aguacate-green/25 rounded-lg  font-semibold">Tu información está siendo validada por nuestro equipo, se te notificará a tu correo electrónico cuando el proceso finalice
      </span>
    </ng-container>
  </ng-container>

 <ng-container *ngIf="userRole === userEnum.aguacate_operator">
  <app-admin-rate></app-admin-rate>
  <app-rate-update-type></app-rate-update-type>
 </ng-container>
</div>
