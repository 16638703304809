import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from '../../services/user.service';
import { EditUserDataComponent } from '../edit-user-data/edit-user-data.component';
import Swal from 'sweetalert2';
import { SetupMfaComponent } from './setup-mfa/setup-mfa.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent implements OnInit, OnDestroy {

  userData: User | undefined;
  subscription: Subscription = new Subscription();
  userSession: string = '';
  MFAauthStatus: boolean = false;
  constructor( private _snackBar: MatSnackBar, private userService: UserService, private setupMFaDialog: MatDialog, private cognito: CognitoService, private userProfileDialog: MatDialog) {
    this.initUserForm();
    this.getPreferredMFA();
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  toggleMfa(){
    console.log(this.MFAauthStatus);
    if(this.MFAauthStatus){
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Estás a punto de habilitar la autenticación multifactor",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1e5a3f',
      confirmButtonText: 'Sí, habilitar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
      try {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.maxHeight = '90vh';
        dialogConfig.maxWidth= '100vw'
        dialogConfig.data = this.userData;

        this.setupMFaDialog.open(SetupMfaComponent, dialogConfig);
      } catch (error) {

      }
      }
    });
  }
  }

  async getPreferredMFA(){
    const mfaStatus = await this.cognito.getMFAStatus();
    console.log(mfaStatus);
    switch(mfaStatus){
      case 'NOMFA':
        this.MFAauthStatus  = false;
        break;
      default:
        this.MFAauthStatus = true;
        break;
    }
    console.log(this.MFAauthStatus );
  }

  openDialog(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth= '100vw'
    dialogConfig.data = this.userData;

    this.userProfileDialog.open(EditUserDataComponent, dialogConfig).afterClosed().subscribe(res=>{
      if(res === true){
        this._snackBar.open('Datos actualizados', 'Ok');
        this.fecthUserData();
        return;
      }
      else if(res === false){
        this._snackBar.open('Hubo un error al actualizar los datos', 'Ok');
      }

    });
  }
  async initUserForm(){
    const userInSession = await this.cognito.getUser();
    this.userSession = userInSession.attributes['email'];
    this.fecthUserData();
  }
  fecthUserData(){
    this.subscription.add(this.userService.getUserData(this.userSession).subscribe(({user}: any)=>{
      this.userData = user;
    }));
  }
  ngOnInit() {
  }

}
