import { Component, EventEmitter, Output, OnInit  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { RecipientZelleService } from 'src/app/dashboard/services/recipient-zelle.service';
import { BeneficiarioZelle } from 'src/app/dashboard/interfaces/beneficiario-zelle.interface';
import { AddBeneficiaryZelleComponent } from '../../../recipient-zelle/components/add-beneficiary-zelle/add-beneficiary-zelle.component';

@Component({
  selector: 'app-beneficiary-zelle',
  templateUrl: './beneficiary-zelle.component.html',
  styleUrls: ['./beneficiary-zelle.component.sass']
})
export class BeneficiaryZelleComponent implements OnInit{

  public subscription: Subscription = new Subscription();
  public user: any;
  public searchTxt: string = '';
  public wordFilter: string = '';

  constructor(
    private dialog: MatDialog,
    private recipientcriptoservice: RecipientZelleService,
    private store: Store<AppState>,
  ){}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select('auth').subscribe(({ user }: any) => {
        this.user = user;
        this.getBeneficiarios();
      })
    );
  }

  @Output()
  public changeStep = new EventEmitter<number>();

  @Output()
  public beneficiarioOutput = new EventEmitter<BeneficiarioZelle>();

  @Output()
  public goBack = new EventEmitter<any>();

  public beneficiarioEscogido:BeneficiarioZelle | undefined = undefined;

  public listaBeneficiarios:BeneficiarioZelle[] = [];
  public listaBeneficiariosFiltrados:BeneficiarioZelle[] = [];

  openCreateDialog(){

    const dialogRef = this.dialog.open(AddBeneficiaryZelleComponent, {
      data: {
        userID: this.user?.usuarioId,
        type: 'CREATE',
      },
      disableClose: true,
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if(result == true ){
        this.getBeneficiarios();
      }
    })

  }

  clearInput() {
    this.searchTxt = '';
  }

  elegir( beneficiario: BeneficiarioZelle ):void{
    if(this.beneficiarioEscogido === beneficiario ){
      this.beneficiarioEscogido = undefined;
      return;
    }
    this.beneficiarioEscogido = beneficiario;
  }

  emitChangeStep( ): void{
    this.changeStep.emit( 2 );
    this.beneficiarioOutput.emit( this.beneficiarioEscogido );
  }

  async getBeneficiarios(): Promise<void>{
    this.listaBeneficiarios = await this.recipientcriptoservice.getByUser(this.user?.usuarioId);
    this.listaBeneficiariosFiltrados = this.listaBeneficiarios;
  }

  buscar(event: KeyboardEvent): void {
    var searchWord = this.searchTxt.toLowerCase();
    if(searchWord === '') {
      this.listaBeneficiariosFiltrados = this.listaBeneficiarios;
      return;
    }
    this.listaBeneficiariosFiltrados = this.listaBeneficiarios.filter( beneficiario => {
      let nombreCompleto = beneficiario.nombre + " " + beneficiario.apellido;
      return nombreCompleto.toLowerCase().includes(searchWord.toLowerCase());
    }
    )
  }

  goToBack(): void {
    this.goBack.emit();
  }

}