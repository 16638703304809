import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { } from '@aws-amplify/ui-angular';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { S3 } from 'aws-sdk';
import { VariablesComplianceService } from '../../services/variables-compliance.service';
import { ComentarioCompliance } from '../../interfaces/comentario-compliance';
import { EstadoCompliance } from '../../interfaces/estado-compliance';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { PagadorService } from '../../services/pagador.service';
import { Pagador } from '../../interfaces/pagador';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { ComplianceDialogComponent } from './compliance-dialog/compliance-dialog.component';
import { CreatePayerComponent } from '../payer/create-payer/create-payer.component';

export interface Image {
  key: string;
  url: string;
}

@Component({
  selector: 'app-compliance-user',
  templateUrl: './compliance-user.component.html',
  styleUrls: ['./compliance-user.component.css'],
})
export class ComplianceUserComponent implements OnInit {
  images: Image[] = [];
  id: string = '';
  user: any;
  userEmail: string = '';
  fotoIdFrontal: string = '';
  fotoIdPosterior: string = '';
  mostarFrontal: boolean = true;
  comentariosCompliance: ComentarioCompliance[] = [];
  estadosCompliance: EstadoCompliance[] = [];
  formCompliance: FormGroup | undefined;
  estadoComplianceBase: Object = {};
  pagadores: Pagador[] = [];
  isCompany = false;
  estatuto = '';
  documentoIdTributario = '';
  constructor(
    private spinner: SpinnerService,
    private fb: FormBuilder,
    private varCompliance: VariablesComplianceService,
    private http: HttpClient,
    private _Activatedroute: ActivatedRoute,
    private cognito: CognitoService,
    private userService: UserService,
    private pagadorService: PagadorService,
    private dialog: MatDialog
  ) {
    this.loadUserEmail();
    this.getVariablesCompliance();
    this.id = this._Activatedroute.snapshot.paramMap.get('email') ?? '';
    this.loadUser();
  }

  reloadPagadores() {
    this.pagadorService.getPagadoresByUser(this.user.usuarioId).then(res => {
      this.pagadores = res;
    })
  }

  async loadUser() {
    await this.userService.getUserData(this.id).subscribe(async ({ user }: any) => {
      this.user = user;
      this.isCompany = Boolean(this.user.tipoUsuarioID);
      this.pagadorService.getPagadoresByUser(this.user.usuarioId).then(res => {
        this.pagadores = res;
      })
      this.formCompliance = this.fb.group({
        esListaONU: new FormControl(
          {
            value: this.user.esListaONU,
            disabled: this.user.fuenteComplianceID === 2,
          },
          [Validators.required]
        ),
        politicamenteExpuesto: new FormControl(
          {
            value: this.user.esPoliticamenteExpuesto,
            disabled: this.user.fuenteComplianceID === 2,
          },
          [Validators.required]
        ),
        esRTP: new FormControl(
          {
            value: this.user.esRTP,
            disabled: this.user.fuenteComplianceID === 2,
          },
          [Validators.required]
        ),
      });
      this.getIdImages();
    });
  }
  async loadUserEmail() {
    const userInSession = await this.cognito.getUser();
    this.userEmail = userInSession.attributes.email;
  }

  async getVariablesCompliance() {
    [this.comentariosCompliance, this.estadosCompliance] = await Promise.all([
      this.varCompliance.getComentariosCompliance(),
      this.varCompliance.getEstadosCompliance(),
    ]);
  }

  async getIdImages() {
    const s3 = new S3({
      accessKeyId: environment.s3.accessKeyId,
      secretAccessKey: environment.s3.secretAccessKey,
    });
    const paramsFrontal = {
      Bucket: environment.s3.bucket,
      Key: this.user.fotoIdfrontal || '',
      Expires: 3600,
    };
    const paramsPosterior = {
      Bucket: environment.s3.bucket,
      Key: this.user.fotoIdPosterior || '',
      Expires: 3600,
    };
    if (this.isCompany) {
      const paramsEstatuto = {
        Bucket: environment.s3.bucket,
        Key: this.user.estatuto || '',
        Expires: 3600,
      };
      const paramsIdTributario = {
        Bucket: environment.s3.bucket,
        Key: this.user.documentoIdTributario || '',
        Expires: 3600,
      };
      this.documentoIdTributario = s3.getSignedUrl('getObject', paramsIdTributario);
      this.estatuto = s3.getSignedUrl('getObject', paramsEstatuto);
    }

    const signedURLFrontal = s3.getSignedUrl('getObject', paramsFrontal);
    const signedURLPosterior = s3.getSignedUrl('getObject', paramsPosterior);

    this.http
      .get(signedURLFrontal, { responseType: 'blob' })
      .subscribe((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          this.fotoIdFrontal = reader.result as string;
        };
      });

    this.http
      .get(signedURLPosterior, { responseType: 'blob' })
      .subscribe((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          this.fotoIdPosterior = reader.result as string;
        };
      });
  }

  openTab(url: string) {
    console.log(url);
    window.open(url, "_blank");
  }

  async ngOnInit() { }

  aproveCompliance() {
    const ref = this.spinner.start();
    this.userService
      .updateUserCompliance(
        this.user.email,
        this.userEmail,
        {
          comentarioCompliance: 0,
          estadoCompliance: 3
        }
      )
      .subscribe(
        (res) => {
          this.loadUser();
          Swal.fire({
            icon: 'success',
            title: 'Compliance actualizado',
            text: 'Se ha aprobado el usuario con éxito',
            showCancelButton: false,
            showDenyButton: false,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#1e5a3f',

          });
          this.spinner.stop(ref);
        },
        (err) => this.spinner.stop(ref)
      );
  }

  backlistUser() {
    const ref = this.spinner.start();
    this.userService
      .updateUserCompliance(
        this.user.email,
        this.userEmail,
        {
          comentarioCompliance: 0,
          estadoCompliance: 2
        }
      )
      .subscribe(
        (res) => {
          this.loadUser();
          Swal.fire({
            icon: 'success',
            title: 'Compliance actualizado',
            text: 'Se ha marcado el usuario como blacklist',
            showCancelButton: false,
            showDenyButton: false,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#1e5a3f',

          });
          this.spinner.stop(ref);
        },
        (err) => this.spinner.stop(ref)
      );
  }
  rejectUser() {
    this.dialog.open(ComplianceDialogComponent, {
      data: {
        userEmail: this.userEmail,
        user: this.user,
        comentariosCompliance: this.comentariosCompliance
      }
    }).afterClosed().subscribe(res => {
      if (res === true) {
        this.loadUser();
      }
    })
  }
  guardarCompliance() {
    if (this.formCompliance?.invalid || !this.formCompliance?.touched) {
      return;
    }
    const ref = this.spinner.start();
    this.userService
      .updateUserChecks(
        this.user.email,
        this.userEmail,
        this.formCompliance.value
      )
      .subscribe(
        (res) => {
          Swal.fire({
            icon: 'success',
            title: 'Compliance actualizado',
            text: 'Se ha actualizado la información de compliance del usuario',
            showCancelButton: false,
            showDenyButton: false,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#1e5a3f',

          });
          this.loadUser();
          this.spinner.stop(ref);
        },
        (err) => this.spinner.stop(ref)
      );
  }

  openAddPayer() {
    this.dialog.open(CreatePayerComponent, {
      data: { email: this.id, id: this.user.usuarioId },
      height: '80%',
      width: '90%',
    }).afterClosed().subscribe(res => {
      if (res === true) {
        this.reloadPagadores();
      }
    });
  }
}
