import * as moment from "moment-timezone";
import { CurrencyPipe } from '@angular/common';

export function getColumns(currencyPipe: CurrencyPipe) {
  return [
    {
      columnDef: 'idTransferencia',
      header: 'ID',
      cell: (element: any) => `${element.idTransferencia}`,
    },
    {
      columnDef: 'fechaInicio',
      header: 'Fecha inicio',
      cell: (element: any) => `${element.fechaInicio ? moment(new Date(element.fechaInicio), 'America/Santiago').format('lll'): ''}`,
    },
    {
      columnDef: 'fechaFondoWallet',
      header: 'Fecha fondo wallet',
      cell: (element: any) => `${element.fechaFondoWallet ?  moment(new Date(element.fechaFondoWallet), 'America/Santiago').format('lll')  : 'No aplica' }`,
    },
    {
      columnDef: 'fechaTermino',
      header: 'Fecha de termino',
      cell: (element: any) => `${element.fechaTermino  ?  moment(new Date(element.fechaTermino), 'America/Santiago').format('lll') : 'No aplica'}`,
    },
    {
      columnDef: 'user',
      header: 'Usuario',
      cell: (element: any) => `${element.usuarioId}`,
    },
    {
      columnDef: 'remitente',
      header: 'Remitente',
      cell: (element: any) => `${element.remitente}`,
    },
    {
      columnDef: 'beneficiario',
      header: 'Beneficiaro',
      cell: (element: any) => `${element.beneficiario ? element.beneficiario : 'No aplica'}`,
    },
    {
      columnDef: 'tn',
      header: 'Tasa de negociación',
      cell: (element: any) => currencyPipe.transform(element.tn, '$'),
    },
    {
      columnDef: 'montoCLP',
      header: 'Monto CLP',
      cell: (element: any) => currencyPipe.transform(element.montoCLP, '$'),
    },
    {
      columnDef: 'montoUSDT',
      header: 'Monto USDT',
      cell: (element: any) => currencyPipe.transform(element.montoUSDT, '$'),
    },
    {
      columnDef: 'walletTRC20',
      header: 'Wallet TRC20',
      cell: (element: any) => `${element.walletTRC20}`,
    },
    {
      columnDef: 'estadoActual',
      header: 'EstadoActual',
      cell: (element: any) => `${element.estadoActual.replaceAll('_',' ')}`,
    },
  ]

}


export const  displayedColumns: string[] = [
  'idTransferencia',
  'fechaInicio',
  'fechaFondoWallet',
  'fechaTermino',
  'user',
  'remitente',
  'beneficiario',
  'tn',
  'montoCLP',
  'montoUSDT',
  'walletTRC20',
  'estadoActual',
  'acciones',
];
