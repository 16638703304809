import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { EstadosUsuario } from 'src/app/shared/enums/estados-usuario.enum';
import { UserRoles } from 'src/app/shared/enums/roles-enum';
import Swal from 'sweetalert2';
import { CartolaService } from '../../services/cartola.service';
import { ActionsService } from 'src/app/shared/services/actions.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.sass'],
})
export class UserHomeComponent implements OnDestroy, AfterViewInit {
  subscription: Subscription = new Subscription();
  userRole: UserRoles = UserRoles.aguacate_client;
  user: any;
  nombreUsuario: string = '';
  userEnum = UserRoles;
  estadoValidacion: number = -1;
  saldoActual = 0;
  sobregiroDisponible :number|undefined=0;
  saldoContable :number|undefined= 0;
  lineaCreditoBloqueada:boolean|undefined=false;
  lineaCredito:number|undefined=0;
  userEmail:string='';
  userEmail2:string='';


  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private cartolaService: CartolaService,
    private actionsService: ActionsService,
    private spinner: SpinnerService, 
    ) {
    let spinnerRef = this.spinner.start();
    this.subscription.add(
      this.store.select('auth').subscribe(async ({ user, userRole }: any) => {
        this.userRole = userRole;
        this.user = user;
        this.userEmail=user?.email;
        if(this.userEmail){
          this.userEmail2 = await this.generateCod(this.userEmail.toLocaleLowerCase().toString());
        }
        if (this.userRole === UserRoles.aguacate_client) {
          if (!this.isNotNullOrEmpty(user)) {
            this.estadoValidacion = EstadosUsuario.incompleto;
            return;
          }
          if (
            this.isNotNullOrEmpty(user) &&
            [0, 1, 2].includes(user.estadoCompliance.estadoID)
          ) {
            this.estadoValidacion = EstadosUsuario.pendienteValidacion;
          }
          if (
            this.isNotNullOrEmpty(user) &&
            user.estadoCompliance.estadoID === 3
          ) {
            this.estadoValidacion = EstadosUsuario.validado;
            this.obtenerSaldo();
          }
        }
      })
    );
    this.subscription.add(
      this.actionsService.subjectSaldo.subscribe((res)=> {
        console.log(res);
        if(res ===true){
          console.log(res);
          this.obtenerSaldo();
        }
      })
    )
    this.spinner.stop(spinnerRef);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async generateCod(input: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    return hashHex;
  }

  isNotNullOrEmpty(object: Object) {
    return object && Object.keys(object).length > 0;
  }

  async obtenerSaldo(){
    if(this.user?.usuarioId){
      const {saldo,lineaCreditoId,balancePermitido,lineaBloqueada,sobregiro} = await this.cartolaService.getSaldoCliente(this.user.usuarioId);
      this.saldoActual =saldo;
      this.sobregiroDisponible=sobregiro;
      this.saldoContable = balancePermitido;
      this.lineaCreditoBloqueada=lineaBloqueada;
      this.lineaCredito=lineaCreditoId;
    }
  }

  enviarDinero(){
    Swal.fire({
      title: 'Enviar dinero',
      text:'Selecciona el tipo de envío',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonColor: '#1e5a3f',
      denyButtonColor: '#1e5a3f',
      confirmButtonText: 'Uno a uno',
      denyButtonText: `Múltiple`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['wallet/client/single']);
      } else if (result.isDenied) {
        this.router.navigate(['wallet/client/multiple']);
      }
    })
  }
}
