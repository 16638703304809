import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BinanceService } from 'src/app/dashboard/services/binance.service';
import { SetValuesComponent } from './set-values/set-values.component';
import { Clipboard } from '@angular/cdk/clipboard';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manual-rate',
  templateUrl: './manual-rate.component.html',
  styleUrls: ['./manual-rate.component.sass']
})
export class ManualRateComponent {
  currentRates: any;
  currentValues: any;
  constructor(private binanceService: BinanceService,  private dialog: MatDialog, private clipboard: Clipboard){
    this.getCurrentRates();
    this.getCurrentValues();
  }
  async getCurrentRates(){
    this.currentRates = await this.binanceService.getCurrentRates();
  }
  async getCurrentValues(){
    this.currentValues = await this.binanceService.getConversionData();
  }

  copiarTasa(){
 const message = `ACT🏃🏻🔥🇻🇪
**Minimo ${this.currentValues.limiteSuperior} Bs**
BANESCO ${parseFloat(this.currentRates.maximo.tasaBanesco).toFixed(5)}
PROVINCIAL ${parseFloat(this.currentRates.maximo.tasaProvincial).toFixed(5)}
MERCANTIL ${parseFloat(this.currentRates.maximo.tasaMercantil).toFixed(5)}
VENEZUELA ${parseFloat(this.currentRates.maximo.tasaVenezuela).toFixed(5)}

**Minimo ${this.currentValues.limiteInferior} Bs**
BANESCO ${parseFloat(this.currentRates.minimo.tasaBanesco).toFixed(5)}
PROVINCIAL ${parseFloat(this.currentRates.minimo.tasaProvincial).toFixed(5)}
MERCANTIL ${parseFloat(this.currentRates.minimo.tasaMercantil).toFixed(5)}
VENEZUELA ${parseFloat(this.currentRates.minimo.tasaVenezuela).toFixed(5)}

Favor enviar datos bancarios sin puntos ni guiones

**Disclaimer: al enviar pedido el cliente acepta que su pago puede demorar, no es permitido devolución ni cambio de tasa**`;
    this.clipboard.copy(message);
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    })

    Toast.fire({
      icon: 'success',
      title: 'Tasas Manuales copiadas en el portapapeles'
    });
  }

  async actualizarTasa(){
    await this.getCurrentRates();
    let data = {
      tasaBanesco: this.currentRates.valoresVES.valorBanesco,
      tasaProvincial: this.currentRates.valoresVES.valorProvincial,
      tasaVenezuela: this.currentRates.valoresVES.valorVenezuela,
      tasaMercantil: this.currentRates.valoresVES.valorMercantil,
      tasaCLP: this.currentRates.tasaCLP,
      porcentajeComision: this.currentRates.comision,
      fuente: 'MANUAL'
    }
    this.dialog.open(SetValuesComponent, { data }).afterClosed().subscribe(res=>{
      if(res && res ===true){
        this.getCurrentRates();
        this.getCurrentValues();
      }
    })
  }
}
