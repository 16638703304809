import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-setup-mfa',
  templateUrl: './setup-mfa.component.html',
  styleUrls: ['./setup-mfa.component.sass']
})
export class SetupMfaComponent {
  qrData = '';
  step = 1;
  code = '';
  constructor(public dialogRef: MatDialogRef<SetupMfaComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private cognito: CognitoService) {
    this.generateQRCode();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async generateQRCode() {
    this.qrData = await this.cognito.generateQRCode();
  }

  async nextStep(step: number) {
    if (step === 1) {
      this.step = 2;
    } else {
      this.code = this.code.replace(/\s/g, '');
      const result = await this.cognito.verifySoftwareToken(this.code);
      console.log(result);
      if(result && result.Status === 'SUCCESS'){
        this.cognito.enableMFA();
        this.dialogRef.close();
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'El código ingresado no es correcto'
        })
      }

    }
  }

}
