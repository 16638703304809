<div class="mt-4 w-full flex flex-row ">
  <div class=" py-4 w-full  ">
    <div class="flex flex-col justify-center items-center" *ngIf="calculatorForm" [formGroup]="calculatorForm">
      <h2 class="px-4 py-4 text-base md:text-xl"><b>¿Cuánto dinero te gustaría enviar?</b></h2>
      <h2 class="font-bold mb-4">Horario de operación: 9:00 - 17:00 </h2>
      <div class="w-11/12 grid grid-cols-3 gap-3">

        <mat-form-field appearance="fill" class="col-span-3 md:col-span-1">
          <mat-label>Desde</mat-label>
          <mat-select formControlName="origen">
            <mat-option value="CL">Chile</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-span-3 md:col-span-2">
          <mat-label>Tu envías</mat-label>
          <input matInput matInputCommified appOnlynumber #input maxlength="10" placeholder="Ej 20000"
            formControlName="envia" (input)="onInputChange($event)" (keyup)="changeValue('origen')">
        </mat-form-field>
      </div>
      <div class="w-11/12 grid grid-cols-3 gap-2">

        <mat-form-field class="col-span-3 md:col-span-1">
          <mat-label>Banco</mat-label>
          <mat-select required formControlName="banco" (selectionChange)="onBankSelection()">
            <mat-option>Selecione el banco</mat-option>
            <mat-option *ngFor="let banco of bancos" [value]="banco">
              Banco {{ banco.nombre | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-span-3 md:col-span-2 ">
          <mat-label>Tu destinatario recibe</mat-label>

          <input matInput matInputCommified appOnlynumber #input maxlength="10" placeholder="Ej 20000"
            formControlName="recibe" (keyup)="changeValue('destino')">
          <mat-error *ngIf="calculatorForm.get('recibe')!.hasError('min')">
            Debe enviar al menos {{+currentValues.limiteInferior | number : '1.2-2' }} Bs
          </mat-error>
        </mat-form-field>

      </div>
      <div class="w-11/12 flex flex-row justify-center gap-6 mt-4">
        <div class="px-6 py-4 w-full bg-white rounded-lg ">
          <p class="font-bold"> Tasa de cambio:</p>
          <p> <span class="font-bold">Mínimo {{currentValues.limiteInferior | number : '1.2-2' }} bs o
              {{currentValues.limiteInferior / currentRateMinimo | number : '1.2-2'}} CLP :</span> </p>
          <p> 1 CLP = {{currentRateMinimo | number : '1.2-5'}} bs</p>
          <p class="font-bold"> <span class="font-bold"> Mínimo {{currentValues.limiteSuperior | number : '1.2-2'}} bs o
              {{currentValues.limiteSuperior / currentRateMaximo | number : '1.2-2'}} CLP: </span> </p>
          <p> 1 CLP = {{currentRateMaximo | number : '1.2-5'}} bs </p>
          <p> <span class="font-bold">Tiempo máximo de envío: </span> 1 hora</p>
        </div>
      </div>

    </div>