import { MaxSizeValidator } from '@angular-material-components/file-input';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { Country } from 'src/app/dashboard/interfaces/country.interface';
import { Pagador } from 'src/app/dashboard/interfaces/pagador';
import { CountriesService } from 'src/app/dashboard/services/countries.service';
import { PagadorService } from 'src/app/dashboard/services/pagador.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { Observable, map, startWith } from 'rxjs';
import Swal from 'sweetalert2';
import { S3 } from 'aws-sdk';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { validateRut } from '@fdograph/rut-utilities';
import { UserRoles } from 'src/app/shared/enums/roles-enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-payer',
  templateUrl: './create-payer.component.html',
  styleUrls: ['./create-payer.component.sass'],
})
export class CreatePayerComponent implements OnInit {
  tipoUsuario: number = 0;
  email: string = '';
  userId: string = '';
  currentRole: string = UserRoles.aguacate_client;
  isAdmin: boolean = false;
  datosForm: FormGroup | undefined;
  tiposID = ['PASAPORTE', 'RUT'];
  paises: Country[] = [];
  esEditar = false;
  pagadorId = '';
  pagador: Pagador | undefined;
  sexos = ['Masculino', 'Femenino'];
  maxDate: Date = new Date();
  optionsPaisResidencia: Observable<Country[]> | undefined;
  optionsPrefijoTelefono: Observable<Country[]> | undefined;
  optionsNacionalidad: Observable<Country[]> | undefined;
  fotoIdFrontal: string = '';
  fotoIdPosterior: string = '';
  erut: string = '';
  documentoTributario: string = '';
  estatuto: string = '';
  @ViewChild('fileInputFrontal') fileInputFrontal: ElementRef<any> | null =
    null;
  @ViewChild('fileInputPosterior') fileInputPosterior: ElementRef<any> | null =
    null;
  @ViewChild('fileInputRutE') fileInputERut: ElementRef<any> | null =
  null;
  @ViewChild('fileInputEstatuto') fileInputEstatuto: ElementRef<any> | null =
    null;
  @ViewChild('fileInputdocumentoId') fileInputdocumentoId: ElementRef<any> | null =
    null;
  fileFrontal: File | undefined;
  filePosterior: File | undefined;
  fileRutE: File | undefined;
  fileEstatuto: File | undefined;
  filedocumentoId: File | undefined;
  validPayerId = true;
  constructor(
    private cognitoService: CognitoService,
    private store: Store<AppState>,
    private router: Router,
    private __snackBar: MatSnackBar,
    private fb: FormBuilder,
    private countryService: CountriesService,
    private pagadorService: PagadorService,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private dialogRef: MatDialogRef<CreatePayerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    this.pagadorId = this.route.snapshot.params['payerId'];
    this.loadUserData();
    this.countryService.getCountryList().subscribe((countryList) => {
      this.paises = countryList;
    });
    if (this.pagadorId) {
      this.esEditar = true;
      this.loadPagador();
    } else {
      this.datosForm = this.fb.group({
        prefijoTelefono: new FormControl('+56', [Validators.required]),
        telefono: new FormControl('', [
          Validators.required,
          Validators.maxLength(15),
        ]),
        ciudadResidencia: new FormControl('', [
          Validators.required,
          Validators.maxLength(30),
        ]),
        paisResidencia: new FormControl('', [Validators.required]),
        direccion: new FormControl('', [
          Validators.required,
          Validators.maxLength(80),
        ]),

        documentoFrontal: new FormControl('', [
          Validators.required,
          MaxSizeValidator(5 * 1000000),
        ]),
        documentoPosterior: new FormControl('', [
          Validators.required,
          MaxSizeValidator(5 * 1000000),
        ]),
        // Persona-specific field
        sexo: new FormControl('', [Validators.required]),
        fechaNacimiento: new FormControl('', [Validators.required]),
        profesion: new FormControl('', [
          Validators.required,
          Validators.maxLength(30),
        ]),
        nombre: new FormControl('', [
          Validators.required,
          Validators.maxLength(40),
        ]),
        apellidos: new FormControl('', [
          Validators.required,
          Validators.maxLength(40),
        ]),
        tipoId: new FormControl('', [Validators.required]),
        numeroId: new FormControl('', [
          Validators.required,
          Validators.maxLength(15),
        ]),
        nacionalidad: new FormControl('', [Validators.required]),
        // Empresa-specific field
        estatuto: new FormControl('', [Validators.required]),
        e_rut: new FormControl('', [Validators.required]),
        documentoId: new FormControl('', [Validators.required]),
        razonSocial: new FormControl('', [Validators.required]),
        rutEmpresa: new FormControl('', [Validators.required]),
        nombreComercial: new FormControl('', [Validators.required]),
        domicilioFiscal: new FormControl('', [Validators.required]),
      });
      this.datosForm.get('tipo_usuario_id')?.valueChanges.subscribe((value) => {
        this.toggleTipoUsuario(value);
      });
    }
    this.datosForm?.get('documentoFrontal')?.valueChanges.subscribe((res) => {
      this.fileCheck('documentoFrontal');
    });
    this.datosForm?.get('documentoPosterior')?.valueChanges.subscribe((res) => {
      this.fileCheck('documentoPosterior');
    });
    this.datosForm?.get('e_rut')?.valueChanges.subscribe((res) => {
      this.fileCheck('e_rut');
    });
    this.datosForm?.get('estatuto')?.valueChanges.subscribe((res) => {
      this.fileCheck('estatuto');
    });
    this.datosForm?.get('documentoId')?.valueChanges.subscribe((res) => {
      this.fileCheck('documentoId');
    });
    this.countryService.getCountryList().subscribe((countryList) => {
      this.paises = countryList;
    });

    this.optionsPrefijoTelefono = this.datosForm?.controls[
      'prefijoTelefono'
    ].valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
    this.optionsPaisResidencia = this.datosForm?.controls[
      'paisResidencia'
    ].valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
    this.optionsNacionalidad = this.datosForm?.controls[
      'nacionalidad'
    ].valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );

    this.disableAndClearValidatorsForEmpresaFields();
  }
  async checkPayer(controlName: string) {
    if(this.tipoUsuario === 1 && controlName === 'numeroId'){
      return;
    }
    this.validPayerId = false;
    const rut = this.datosForm?.controls[controlName].value;
    try{
      const result = await this.pagadorService.checkPayerExist(rut);
      this.validPayerId = true;
    }catch(e:any){
      if(e.status===400){
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: e.error.message,
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#1e5a3f',
        });
      }
    }

  }
  async fileCheck(formControlName: string, acceptPDF = true) {
    this.datosForm?.controls[formControlName].setErrors(null);
    let isValid = false;
    const file = this.datosForm?.controls[formControlName].value;
    const isPNG = this.check([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]);
    const isJPEG = this.check([0xff, 0xd8, 0xff]);
    const isPDF = this.check([0x25, 0x50, 0x44, 0x46, 0x2D]);
    const buffers: Buffer = await this.readBuffer(file, 0, 8);
    const uint8Array = new Uint8Array(buffers);
    if (isPNG(uint8Array)) {
      isValid = true;
    }
    if (isJPEG(uint8Array)) {
      isValid = true;
    }
    if(acceptPDF && isPDF(uint8Array)){
      isValid = true;
    }
    if (!isValid) {
      this.datosForm?.controls[formControlName].setErrors({
        incorrectFormat: true,
      });
    }
  }

  async fileCheckType(file: File) {
    let isValid = false;
    const isPNG = this.check([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]);
    const isJPEG = this.check([0xff, 0xd8, 0xff]);
    const buffers: Buffer = await this.readBuffer(file, 0, 8);
    const uint8Array = new Uint8Array(buffers);
    if (isPNG(uint8Array)) {
      isValid = true;
    }
    if (isJPEG(uint8Array)) {
      isValid = true;
    }
    return isValid;
  }

  readBuffer(file: File, start = 0, end = 2): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file.slice(start, end));
    });
  }

  check(headers: any) {
    return (buffers: any, options = { offset: 0 }) =>
      headers.every(
        (header: any, index: any) => header === buffers[options.offset + index]
      );
  }

  checkPrefijo() {
    setTimeout(() => {
      if (
        this.paises.findIndex(
          (elem: Country) =>
            elem.DIAL === this.datosForm?.controls['prefijoTelefono'].value
        ) === -1
      ) {
        this.datosForm?.controls['prefijoTelefono'].setValue(null);
      }
    }, 500);
  }

  checkPais(formControlName: string) {
    setTimeout(() => {
      if (
        this.paises.findIndex(
          (elem: Country) =>
            elem.NAME === this.datosForm?.controls[formControlName].value
        ) === -1
      ) {
        this.datosForm?.controls[formControlName].setValue(null);
      }
    }, 500);
  }

  toggleTipoUsuario(selectedType: string): void {
    if (selectedType === 'persona') {
      this.tipoUsuario = 0;
      this.showPersonaFields();
    }
    if (selectedType === 'empresa') {
      this.tipoUsuario = 1;
      this.showEmpresaFields();
    }
  }

  showPersonaFields(): void {
    if (this.datosForm) {

      this.datosForm.get('sexo')?.setValidators([Validators.required]);
      this.datosForm.get('fechaNacimiento')?.setValidators([Validators.required]);
      this.datosForm.get('profesion')?.setValidators([
        Validators.required,
        Validators.maxLength(30),
      ]);
      this.datosForm.get('tipoId')?.setValidators([Validators.required]);
      this.datosForm.get('numeroId')?.setValidators([
        Validators.required,
        Validators.maxLength(15),
      ]);
      this.datosForm.get('nacionalidad')?.setValidators([Validators.required]);

      this.disableAndClearValidatorsForEmpresaFields();
    }
  }

  private disableAndClearValidatorsForEmpresaFields(): void {
    if(this.datosForm){
      this.datosForm.get('estatuto')?.reset();
      this.datosForm.get('e_rut')?.reset();
      this.datosForm.get('documentoId')?.reset();
      this.datosForm.get('razonSocial')?.reset();
      this.datosForm.get('rutEmpresa')?.reset();
      this.datosForm.get('nombreComercial')?.reset();
      this.datosForm.get('domicilioFiscal')?.reset();
      // this.datosForm.get('paisResidencia')?.reset();

      this.datosForm.get('estatuto')?.setValidators([]);
      this.datosForm.get('e_rut')?.setValidators([]);
      this.datosForm.get('documentoId')?.setValidators([]);
      this.datosForm.get('razonSocial')?.setValidators([]);
      this.datosForm.get('rutEmpresa')?.setValidators([]);
      this.datosForm.get('nombreComercial')?.setValidators([]);
      this.datosForm.get('domicilioFiscal')?.setValidators([]);
      // this.datosForm.get('paisResidencia')?.setValidators([]);

      this.datosForm.get('estatuto')?.updateValueAndValidity();
      this.datosForm.get('e_rut')?.updateValueAndValidity();
      this.datosForm.get('documentoId')?.updateValueAndValidity();
      this.datosForm.get('razonSocial')?.updateValueAndValidity();
      this.datosForm.get('rutEmpresa')?.updateValueAndValidity();
      this.datosForm.get('nombreComercial')?.updateValueAndValidity();
      this.datosForm.get('domicilioFiscal')?.updateValueAndValidity();
      // this.datosForm.get('paisResidencia')?.updateValueAndValidity();
    }
  }

  showEmpresaFields(): void {
    if (this.datosForm) {
      this.datosForm.get('estatuto')?.setValidators([Validators.required]);
      this.datosForm.get('e_rut')?.setValidators([Validators.required]);
      this.datosForm.get('documentoId')?.setValidators([Validators.required]);
      this.datosForm.get('razonSocial')?.setValidators([Validators.required]);
      this.datosForm.get('rutEmpresa')?.setValidators([Validators.required]);
      this.datosForm.get('nombreComercial')?.setValidators([Validators.required]);
      this.datosForm.get('domicilioFiscal')?.setValidators([Validators.required]);

      this.disableAndClearValidatorsForPersonaFields();
    }
  }

  private disableAndClearValidatorsForPersonaFields(): void {
    if(this.datosForm){
      this.datosForm.get('sexo')?.reset();
      this.datosForm.get('fechaNacimiento')?.reset();
      this.datosForm.get('profesion')?.reset();
      this.datosForm.get('nacionalidad')?.reset();
      this.datosForm.get('ciudadResidencia')?.reset();
      this.datosForm.get('direccion')?.reset();
      this.datosForm.get('paisResidencia')?.reset();

      this.datosForm.get('sexo')?.setValidators([]);
      this.datosForm.get('fechaNacimiento')?.setValidators([]);
      this.datosForm.get('profesion')?.setValidators([]);
      this.datosForm.get('nacionalidad')?.setValidators([]);
      this.datosForm.get('ciudadResidencia')?.setValidators([]);
      this.datosForm.get('direccion')?.setValidators([]);
      this.datosForm.get('paisResidencia')?.setValidators([]);


      this.datosForm.get('sexo')?.updateValueAndValidity();
      this.datosForm.get('fechaNacimiento')?.updateValueAndValidity();
      this.datosForm.get('profesion')?.updateValueAndValidity();
      this.datosForm.get('nacionalidad')?.updateValueAndValidity();
      this.datosForm.get('ciudadResidencia')?.updateValueAndValidity();
      this.datosForm.get('direccion')?.updateValueAndValidity();
      this.datosForm.get('paisResidencia')?.updateValueAndValidity();

    }
  }

  private _filter(value: string): Country[] {
    if (!value) {
      return this.paises;
    }
    const filterValue = value.toLowerCase();

    return this.paises.filter(
      (option) =>
        option.NAME.toLowerCase().includes(filterValue) ||
        option.DIAL.includes(filterValue)
    );
  }

  async validateRutEmpresa(){
    await this.checkPayer('rutEmpresa');
    if(this.datosForm){
      if ( this.datosForm.controls['rutEmpresa'].hasError('incorrectFormat')) {
        this.datosForm.controls['rutEmpresa'].setErrors({incorrectFormat: null});
        this.datosForm.controls['rutEmpresa'].updateValueAndValidity();
      }

      const rut = this.datosForm.controls['rutEmpresa'].value;
      console.log(rut);
      console.log(validateRut(rut));
      if(validateRut(rut)){
        this.datosForm.controls['rutEmpresa'].setErrors({incorrectFormat: null});
        this.datosForm.controls['rutEmpresa'].updateValueAndValidity();
      }else{
        this.datosForm.controls['rutEmpresa'].setErrors({incorrectFormat: true});
      }
    }

  }

  getFormValidationErrors() {
    if(this.datosForm){
      Object.keys(this.datosForm.controls).forEach(key => {
        const controlErrors = this.datosForm?.get(key)?.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
           console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
    }
  }

  async getIdImages() {
    const s3 = new S3({
      accessKeyId: environment.s3.accessKeyId,
      secretAccessKey: environment.s3.secretAccessKey,
    });
    if(this.pagador?.fotoIdfrontal){
      const paramsFrontal = {
        Bucket: environment.s3.bucket,
        Key: this.pagador?.fotoIdfrontal,
        Expires: 3600,
      };
      const signedURLFrontal = s3.getSignedUrl('getObject', paramsFrontal);
      this.http
      .get(signedURLFrontal, { responseType: 'blob' })
      .subscribe((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          this.fotoIdFrontal = reader.result as string;
        };
      });
    }
    if(this.pagador?.fotoIdPosterior){
      const paramsPosterior = {
        Bucket: environment.s3.bucket,
        Key: this.pagador?.fotoIdPosterior,
        Expires: 3600,
      };
      const signedURLPosterior = s3.getSignedUrl('getObject', paramsPosterior);
      this.http
      .get(signedURLPosterior, { responseType: 'blob' })
      .subscribe((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          this.fotoIdPosterior = reader.result as string;
        };
      });
    }

    if(this.pagador?.estatuto){
      const paramsEstatuto = {
        Bucket: environment.s3.bucket,
        Key: this.pagador?.estatuto,
        Expires: 3600,
      };
      const signedURLEstatuto = s3.getSignedUrl('getObject', paramsEstatuto);
      this.http
      .get(signedURLEstatuto, { responseType: 'blob' })
      .subscribe((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          this.estatuto = reader.result as string;
        };
      });
    }

    if(this.pagador?.e_rut){
      const paramsERut = {
        Bucket: environment.s3.bucket,
        Key: this.pagador?.e_rut,
        Expires: 3600,
      };
      const signedURLERut = s3.getSignedUrl('getObject', paramsERut);
      this.http
      .get(signedURLERut, { responseType: 'blob' })
      .subscribe((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          this.erut = reader.result as string;
        };
      });
    }

    if(this.pagador?.documentoId){
      const paramsDocumentoTributario = {
        Bucket: environment.s3.bucket,
        Key: this.pagador?.documentoId,
        Expires: 3600,
      };
      const signedURLDocumentoTributario = s3.getSignedUrl('getObject', paramsDocumentoTributario);
      this.http
      .get(signedURLDocumentoTributario, { responseType: 'blob' })
      .subscribe((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          this.documentoTributario = reader.result as string;
        };
      });
    }
  }

  async loadPagador() {
    this.pagador = await this.pagadorService.getPagador(this.pagadorId);
    this.getIdImages();
    this.tipoUsuario = this.pagador?.tipoUsuarioId
    if(this.tipoUsuario===0){
      this.datosForm = this.fb.group({
        nombre: new FormControl(this.pagador?.nombre, [Validators.required]),
        apellidos: new FormControl(this.pagador?.apellidos, [Validators.required]),
        tipoId: new FormControl(this.pagador?.tipoId, [Validators.required]),
        numeroId: new FormControl(this.pagador?.numeroId, [Validators.required]),
        nacionalidad: new FormControl(this.pagador?.nacionalidad, [
          Validators.required,
        ]),
        prefijoTelefono: new FormControl(this.pagador?.prefijoTelefono, [
          Validators.required,
        ]),
        telefono: new FormControl(this.pagador?.telefono, [Validators.required]),
        sexo: new FormControl(
          this.pagador?.sexo === 'M' ? 'Masculino' : 'Femenino',
          [Validators.required]
        ),
        fechaNacimiento: new FormControl(this.pagador?.fechaNacimiento, [
          Validators.required,
        ]),
        ciudadResidencia: new FormControl(this.pagador?.ciudadResidencia, [
          Validators.required,
        ]),
        paisResidencia: new FormControl(this.pagador?.paisResidencia, [
          Validators.required,
        ]),
        direccion: new FormControl(this.pagador?.direccion, [
          Validators.required,
        ]),
        profesion: new FormControl(this.pagador?.profesion, [
          Validators.required,
        ]),
        documentoFrontal: new FormControl('', [MaxSizeValidator(5 * 1024)]),
        documentoPosterior: new FormControl('', [MaxSizeValidator(5 * 1024)]),
      });
    }
    if(this.tipoUsuario===1){
      this.datosForm = this.fb.group({
        nombre: new FormControl(this.pagador?.nombre, [Validators.required]),
        apellidos: new FormControl(this.pagador?.apellidos, [Validators.required]),
        tipoId: new FormControl(this.pagador?.tipoId, [Validators.required]),
        numeroId: new FormControl(this.pagador?.numeroId, [Validators.required]),
        prefijoTelefono: new FormControl(this.pagador?.prefijoTelefono, [
          Validators.required,
        ]),
        telefono: new FormControl(this.pagador?.telefono, [Validators.required]),
        documentoFrontal: new FormControl('', [MaxSizeValidator(5 * 1024)]),
        documentoPosterior: new FormControl('', [MaxSizeValidator(5 * 1024)]),
        e_rut: new FormControl('', [MaxSizeValidator(5 * 1024)]),
        estatuto: new FormControl('', [MaxSizeValidator(5 * 1024)]),
        documentoId: new FormControl('', [MaxSizeValidator(5 * 1024)]),
        razonSocial: new FormControl(this.pagador?.razonSocial, [Validators.required]),
        rutEmpresa: new FormControl(this.pagador?.rutEmpresa, [Validators.required]),
        nombreComercial: new FormControl(this.pagador?.nombreComercial, [Validators.required]),
        domicilioFiscal: new FormControl(this.pagador?.domicilioFiscal, [Validators.required]),
      });
    }
  }

  resetValue(doc: string) {
    if (doc === 'documentoFrontal') {
      this.fileInputFrontal!.nativeElement.value = '';
      this.fileFrontal = undefined;
    }

    if (doc === 'documentoPosterior') {
      this.fileInputPosterior!.nativeElement.value = '';
      this.filePosterior = undefined;
    }
    if (doc === 'e_rut') {
      this.fileInputERut!.nativeElement.value = '';
      this.fileRutE = undefined;
    }
    if (doc === 'estatuto') {
      this.fileInputEstatuto!.nativeElement.value = '';
      this.fileEstatuto = undefined;
    }
    if (doc === 'documentoId') {
      this.fileInputdocumentoId!.nativeElement.value = '';
      this.filedocumentoId = undefined;
    }
    Swal.fire({
      icon: 'error',
      title: 'Ha ocurrido un error',
      text: 'El tipo de archivo no es valido',
      showCancelButton: false,
      showDenyButton: false,
      confirmButtonText: 'Ententido',
      confirmButtonColor: '#1e5a3f',
    });
  }

  async showPreview(event: any, doc: string) {
    const inputFile = event.target as HTMLInputElement;
    let file: File | null = inputFile.files ? inputFile.files[0] : null;
    if (file) {
      if (!(await this.fileCheckType(file))) {
        this.resetValue(doc);
        return;
      }
      const reader = new FileReader();
      if (doc === 'documentoFrontal') {
        reader.onloadend = () => {
          if (file) {
            this.fileFrontal = file;
          }
          this.fotoIdFrontal = reader.result as string;
        };
      }
      if (doc === 'documentoPosterior') {
        reader.onloadend = () => {
          if (file) {
            this.filePosterior = file;
          }
          this.fotoIdPosterior = reader.result as string;
        };
      }
      if (doc === 'e_rut') {
        reader.onloadend = () => {
          if (file) {
            this.fileRutE = file;
          }
          this.erut = reader.result as string;
        };
      }
      if (doc === 'estatuto') {
        reader.onloadend = () => {
          if (file) {
            this.fileEstatuto = file;
          }
          this.estatuto = reader.result as string;
        };
      }
      if (doc === 'documentoId') {
        reader.onloadend = () => {
          if (file) {
            this.filedocumentoId = file;
          }
          this.documentoTributario = reader.result as string;
        };
      }
      reader.readAsDataURL(file);
    }
  }

  ngOnInit(): void {
  }

  async loadUserData() {
    const userInSession = await this.cognitoService.getUser();
    if(userInSession.attributes['custom:user-group']===UserRoles.aguacate_client){
      this.email = userInSession.attributes['email'];
    }
    if(userInSession.attributes['custom:user-group']===UserRoles.aguacate_operator){
      this.isAdmin=true;
      this.email = this.data.email;
    }
  }

  async enviarDatos() {
    this.getFormValidationErrors();
    if (!this.datosForm?.valid) return;
    const spinnerRef = this.spinner.start();
    let imagenPosterior = null;
    let imagenFrontal = null;
    let e_rut = null;
    let documentoTributario = null;
    let estatuto = null;
    //(await this.uploadImagesToS3('documentoFrontal')).Key;
    const user: any = { ...this.datosForm.value, tipoUsuarioId: this.tipoUsuario};
    delete user.documentoFrontal;
    delete user.documentoPosterior;
    delete user.e_rut;
    delete user.estatuto;
    delete user.documentoId;
    user.email = this.email;
    if (this.esEditar) {
      user.pagadorId = this.pagadorId
      if (this.fileFrontal) {
        imagenFrontal = await (
          await this.uploadImagesToS3(this.fileFrontal, 'documentoFrontal')
        ).Key;
        user.fotoIdfrontal = imagenFrontal;
      }
      if (this.filePosterior) {
        imagenPosterior = await (
          await this.uploadImagesToS3(this.filePosterior, 'documentoPosterior')
        ).Key;
        user.fotoIdPosterior = imagenPosterior;
      }
      if(this.tipoUsuario===1){
        if (this.filedocumentoId){
          documentoTributario = await (await this.uploadImagesToS3(this.filedocumentoId, 'documentoId')).Key;
          user.documentoId = documentoTributario;
        }
        // if (this.fileRutE){
        //   e_rut = await (await this.uploadImagesToS3(this.fileRutE, 'e_rut')).Key;
        //   user.e_rut=e_rut;
        // }
        if (this.fileEstatuto){
          estatuto = await (await this.uploadImagesToS3(this.fileEstatuto, 'estatuto')).Key;
          user.estatuto = estatuto;
        }
        user.tipoUsuarioId = 1;
      }
      this.pagadorService
        .editarPagador(user)
        .then((res) => {
          this.spinner.stop(spinnerRef);
          if (res) {
            Swal.fire({
              icon: 'success',
              title: 'Pagador actualizado',
              text: 'Se ha actualizado el pagador con éxito',
              showCancelButton: false,
              showDenyButton: false,
              confirmButtonText: 'Entendido',
              confirmButtonColor: '#1e5a3f',
            });

            if(this.isAdmin){
              console.log(this.isAdmin);
              this.dialogRef.close(true);
              return;
            }
            this.router.navigate(['/wallet/client/payerList']);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ha ocurrido un error',
              text: 'No se ha podido actualizar el pagador',
              showCancelButton: false,
              showDenyButton: false,
              confirmButtonText: 'Ententido',
              confirmButtonColor: '#1e5a3f',
            });
          }

        })
        .catch((err) => {
          this.spinner.stop(spinnerRef);
        });
    } else {
      if (user && user.numeroId) {
        user.numeroId = user.numeroId.replace(/[-.]/g, '');
      }
      if (user && user.rutEmpresa) {
        user.rutEmpresa = user.rutEmpresa.replace(/[-.]/g, '');
      }
      imagenFrontal = await(
        await this.uploadImagesToS3(
          this.datosForm.get('documentoFrontal')?.value,
          'documentoFrontal'
        )
      ).Key;
      user.fotoIdfrontal = imagenFrontal;

      imagenPosterior = await(
        await this.uploadImagesToS3(
          this.datosForm.get('documentoPosterior')?.value,
          'documentoPosterior'
        )
      ).Key;
      user.fotoIdPosterior = imagenPosterior;
      if (this.tipoUsuario==1){
        e_rut = await (
          await this.uploadImagesToS3(
            this.datosForm.get('e_rut')?.value,
            'e_rut'
          )
        ).Key;
        user.e_rut = e_rut;

        estatuto = await (
          await this.uploadImagesToS3(
            this.datosForm.get('estatuto')?.value,
            'estatuto'
          )
        ).Key;
        user.estatuto = estatuto;
      }

      this.pagadorService
        .createPagador(user)
        .then((res) => {
          this.spinner.stop(spinnerRef);
          if (res) {
            Swal.fire({
              icon: 'success',
              title: 'Pagador registrado',
              text: 'Se ha registrado el pagador con éxito',
              showCancelButton: false,
              showDenyButton: false,
              confirmButtonText: 'Entendido',
              confirmButtonColor: '#1e5a3f',
            });
            if(this.isAdmin){
              console.log(this.isAdmin);
              this.dialogRef.close(true);
              return;
            }
            this.router.navigate(['/wallet/client/payerList']);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ha ocurrido un error',
              text: 'No se ha podido registrar el pagador',
              showCancelButton: false,
              showDenyButton: false,
              confirmButtonText: 'Ententido',
              confirmButtonColor: '#1e5a3f',
            });
          }

          // this.router.navigate(['/wallet']);
        })
        .catch((err) => {
          this.spinner.stop(spinnerRef);
        });
    }
  }
  cancelar(){
    if(this.isAdmin){
      this.dialogRef.close(false);
      return;
    }
    this.router.navigate(['/wallet/client/payerList'])
  }


  async uploadImagesToS3(file: File, title: string) {
    const user = await this.cognitoService.getUser();
    if(this.isAdmin){
      user.username =  btoa(this.data.email);
    }
    let ext :string |undefined=''; 
    if(file?.name){
      ext= file.name?.split('.').pop();
    }else{
      ext=''
    }
    const s3 = new S3({
      accessKeyId: environment.s3.accessKeyId,
      secretAccessKey: environment.s3.secretAccessKey,
    });
    const paramsFrontal = {
      Bucket: environment.s3.bucket,
      Key: `${user.username}${title}.${ext}`,
      Body: file,
    };
    var promise = await s3.upload(paramsFrontal).promise();
    return promise;
  }
}
