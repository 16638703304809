<div class="p-6 md:p-16 w-full flex flex-col items-center align-middle justify-center">
  <h1 class="font-semibold text-2xl text-center my-5">Mi perfil</h1>
  <div class="justify-self-start my-10 flex flex-row gap-4 ">
    <p class="font-bold">Estado autenticación multifactor:</p>
    <mat-slide-toggle [(ngModel)]="MFAauthStatus" (change)="toggleMfa()" [disabled]="MFAauthStatus">
      {{ MFAauthStatus ? 'Activado' : 'Desactivado' }}
    </mat-slide-toggle>

  </div>
  <div class="w-full md:w-1/3 grid grid-flow-row-dense grid-cols-2 justify-between gap-y-5">
    <div class="justify-self-start">
      <p class="font-bold">Nombre:</p>
      <span >{{userData?.nombre}} {{userData?.apellidos}}</span>
    </div>



    <div class="justify-self-end text-right">
      <p class="font-bold">Documento de identidad:</p>
      <span >{{userData?.tipoId}} {{userData?.numeroId}}</span>
    </div>
    <div class="justify-self-start">
      <p class="font-bold">Sexo:</p>
      <span >{{userData?.sexo === 'M' ? 'Masculino' : 'Femenino'  }}</span>
    </div>

    <div class="justify-self-end text-right">
      <p class="font-bold">Nacionalidad:</p>
      <span >{{userData?.nacionalidad}} </span>
    </div>
    <div class="justify-self-start ">
      <p class="font-bold">Profesión:</p>
      <span >{{userData?.profesion }}</span>
    </div>
    <div class="justify-self-end text-right">
      <p class="font-bold">Dirección:</p>
      <span >{{userData?.ciudadResidencia}}, {{userData?.direccion}} </span>
    </div>
    <div class="justify-self-start ">
      <p class="font-bold">Teléfono:</p>
      <span >{{userData?.prefijoTelefono}} {{userData?.telefono}}</span>
    </div>
    <div class="justify-self-end  text-right">
      <p class="font-bold">Facturas:</p>
      <span >{{userData?.recibeFactura===1?"Facturas Activadas":"Facturas Desactivadas"}}</span>
    </div>
  </div>
  <div class="w-full md:w-1/3 grid grid-flow-row-dense grid-cols-1 self-center gap-y-5 text-center mt-4">
    <button mat-raised-button color="primary" (click)="openDialog()" class="w-1/3 justify-self-center">
      <mat-icon aria-hidden="false" aria-label="editar icon" fontIcon="edit"></mat-icon> Editar datos
    </button>
  </div>
</div>
