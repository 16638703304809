import { Component,Output,EventEmitter,Input } from '@angular/core';
import { ActionsService } from 'src/app/shared/services/actions.service';

@Component({
  selector: 'app-calculator-zelle-cal',
  templateUrl: './calculator-zelle-cal.component.html',
  styleUrls: ['./calculator-zelle-cal.component.sass']
})
export class CalculatorZelleCalComponent {
  public minError = false;
  public maxError = false;
  public clpCurrency: number = 0
  public zelleCurrency: number = 0;
  public minValue: number = 100;
  public maxValue: number = 5000;


  @Input()
  public usdt_clp_tax:number = 0;

  @Input()
  public zelle_buy_rate: number = 0;

  @Input()
  public zelle_sell_rate: number = 0;

  @Input()
  public saldoActual:number = 0;

  @Input()
  public sobregiroDisponible :number|undefined=0;

  @Input()
  public saldoContable :number|undefined= 0;

  @Input()
  public lineaCreditoBloqueada:boolean|undefined=false;

  @Input()
  public lineaCredito:number|undefined=0;


  _limitSell: number = 0;

  @Input() set limitSell(value : number){
    this._limitSell = value;
    this.maxError = false;
    if(this.zelleCurrency > this._limitSell){
      this.maxError = true;
    }
  }


  @Output()
  public changeStep = new EventEmitter<number>();

  @Output()
  public clpCurrencyEmit = new EventEmitter<number>();

  @Output()
  public zelleCurrencyEmit = new EventEmitter<number>();

  @Output()
  public goBack = new EventEmitter<any>();

  constructor(
    private actionService: ActionsService,
  ){
    this.actionService.subjectTasaUSDT.subscribe(
      (res)=>{
        // setTimeout(()=> this.transformCurrency('clp'), 1000)
      }
    )
  }

  get valorClp(): string {
    return this.clpCurrency === 0 ? '' : this.clpCurrency.toLocaleString('en-US');
  }

  set valorClp(val: string) {
    if (val === '') {
      this.clpCurrency = 0;
    } else {
      this.clpCurrency = parseFloat(val.replace(/,/g, ''));
    }
  }

  get valorZelle(): string{
    return this.zelleCurrency === 0 ? '' : this.zelleCurrency.toLocaleString('en-US');
  }

  set valorZelle(val: string) {

    if (val === '') {
      this.zelleCurrency = 0;
    } else {
      this.zelleCurrency = parseFloat(val.replace(/,/g, ''));
    }

  }

  emitChangeStep( ): void{
    if(this.minError)return;
    this.changeStep.emit( 3 );
    this.clpCurrencyEmit.emit( this.clpCurrency );
    this.zelleCurrencyEmit.emit( this.zelleCurrency );
  }

  maxValAvailableCLP( ): void{
    if(this.lineaCredito != null && this.lineaCredito>0 && !this.lineaCreditoBloqueada){
      if(this.saldoContable != null && this.saldoContable > 0){
        this.valorClp=this.saldoContable?.toString() ?? '';
        this.transformCurrency('clp');
      }
    }else{
      if(this.saldoActual>0){
        this.valorClp=this.saldoActual.toString();
        this.transformCurrency('clp');
      }
    }
  }

  clearValAvailableCLP( ): void{
      this.valorClp="0";
      this.transformCurrency('clp');
  }

  transformCurrency( currency: string ): void {
    this.minError=false;
    this.maxError=false;
    if(currency === 'clp'){
      this.zelleCurrency = this.clpCurrency/this.zelle_buy_rate;
    }
    if(currency === 'zelle'){
      this.clpCurrency = this.zelleCurrency*this.zelle_buy_rate;
    }
    if(this.zelleCurrency < this.minValue){
      this.minError = true;
    }
    if(this.zelleCurrency > this._limitSell){
      this.maxError = true;
    }
    if(this.minError)return;
    this.clpCurrencyEmit.emit( this.clpCurrency )
    this.zelleCurrencyEmit.emit( this.zelleCurrency )
  }

  soloNumeros(event: KeyboardEvent) {
    const char = event.key;
    if (!char.match(/[0-9]/)) {
      event.preventDefault();
    }
  }

  goToBack(): void {
    this.goBack.emit();
  }
}
